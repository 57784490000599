import type { LanguageCodesUIValues, LanguageCodesValues } from '../constants/languageCode';
import type { SiteIdData, SiteIdValues } from '../constants/siteId';

import { LOCAL_STORAGE_KEYS } from '@boldpl/base/constants/localStorage';

import {
  LANGUAGE_CODE_DEFAULT,
  LANGUAGE_CODES,
  LANGUAGE_CODES_TO_SHORT,
  LANGUAGE_CODES_UI,
} from '../constants/languageCode';

// NEW_SITE_ID_HERE

const portugueseRegExp = new RegExp(`^${LANGUAGE_CODES_TO_SHORT['pt-PT']}\\b`);
const frenchRegExp = new RegExp(`^${LANGUAGE_CODES_TO_SHORT['fr-FR']}\\b`);
const spanishRegExp = new RegExp(`^${LANGUAGE_CODES_TO_SHORT['es-ES']}\\b`);
const italianRegExp = new RegExp(`^${LANGUAGE_CODES_TO_SHORT['it-IT']}\\b`);
const germanRegExp = new RegExp(`^${LANGUAGE_CODES_TO_SHORT['de-DE']}\\b`);

type ReferrerMap = Partial<SiteIdData<Record<string, LanguageCodesUIValues> | null>>;

const referrerMap: ReferrerMap = {
  interviewme: null,
  resumelab: {
    'resumelab.com/br': 'pt-BR',
    'resumelab.com/de': 'de-DE',
    'resumelab.com/es': 'es-ES',
    'resumelab.com/fr': 'fr-FR',
    'resumelab.com/it': 'it-IT',
    'testing.resumelab.workz.pl/br': 'pt-BR',
    'testing.resumelab.workz.pl/de': 'de-DE',
    'testing.resumelab.workz.pl/es': 'es-ES',
    'testing.resumelab.workz.pl/fr': 'fr-FR',
    'testing.resumelab.workz.pl/it': 'it-IT',
  },
  zetybr: null,
  zetyde: null,
  zetydk: null,
  zetyes: null,
  zetyfr: null,
  zetyit: null,
  zetyse: null,
};

export const getLanguageReferrer = (siteId: SiteIdValues, referrer: string) => {
  // test - referrer, the ones in LANGUAGE_CODES only
  if (process.env.NODE_ENV === 'development') {
    const devReferrer = localStorage.getItem(LOCAL_STORAGE_KEYS.devReferrer) as LanguageCodesValues;

    if (Object.values(LANGUAGE_CODES).includes(devReferrer)) {
      return devReferrer;
    }
  }

  if (!referrer || !referrerMap[siteId]) {
    return null;
  }

  const match = Object.entries(referrerMap[siteId] || {}).find((elem) => referrer.includes(elem[0]));

  return match?.[1] || null;
};

export function getBrowserLanguage() {
  // test - browser language
  if (process.env.NODE_ENV === 'development') {
    // the ones in LANGUAGE_CODES only
    const devInterfaceLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.devNavigatorLanguage) as LanguageCodesUIValues;

    if (Object.values(LANGUAGE_CODES_UI).includes(devInterfaceLanguage)) {
      return devInterfaceLanguage;
    }

    // all
    const devInterfaceLanguageAll = localStorage.getItem(LOCAL_STORAGE_KEYS.devNavigatorLanguageAll);

    if (devInterfaceLanguageAll) {
      return devInterfaceLanguageAll as LanguageCodesUIValues;
    }
  }

  return (
    window.navigator.languages?.[0] ? window.navigator.languages[0] : window.navigator.language || LANGUAGE_CODE_DEFAULT
  ) as LanguageCodesUIValues;
}

const languageBySiteId: Partial<Record<SiteIdValues, (referrer: string) => LanguageCodesUIValues>> = {
  interviewme(): LanguageCodesUIValues {
    return 'pl-PL';
  },
  resumelab(referrer: string): LanguageCodesUIValues {
    const languageFromReferrer = getLanguageReferrer('resumelab', referrer) as LanguageCodesUIValues;

    if (languageFromReferrer) {
      return languageFromReferrer;
    }

    const browserLanguage = getBrowserLanguage();

    if (frenchRegExp.test(browserLanguage)) {
      return 'fr-FR';
    }
    if (spanishRegExp.test(browserLanguage)) {
      return 'es-ES';
    }
    if (portugueseRegExp.test(browserLanguage)) {
      return 'pt-BR';
    }
    if (italianRegExp.test(browserLanguage)) {
      return 'it-IT';
    }
    if (germanRegExp.test(browserLanguage)) {
      return 'de-DE';
    }

    return 'en-US';
  },
  zetybr(): LanguageCodesUIValues {
    return 'pt-BR';
  },
  zetyde(): LanguageCodesUIValues {
    return 'de-DE';
  },
  zetydk(): LanguageCodesUIValues {
    return 'da-DK';
  },
  zetyes(referrer: string): LanguageCodesUIValues {
    const languageFromReferrer = getLanguageReferrer('zetyes', referrer) as LanguageCodesUIValues;

    if (languageFromReferrer) {
      return languageFromReferrer;
    }

    const browserLanguage = getBrowserLanguage();

    if (portugueseRegExp.test(browserLanguage)) {
      return 'pt-BR';
    }

    return 'es-ES';
  },
  zetyfr(): LanguageCodesUIValues {
    return 'fr-FR';
  },
  zetyit(): LanguageCodesUIValues {
    return 'it-IT';
  },
  zetyse(): LanguageCodesUIValues {
    return 'sv-SE';
  },
};

function getLanguage(siteId: SiteIdValues, referrer = window.document.referrer): LanguageCodesUIValues {
  // test - app language
  if (process.env.NODE_ENV === 'development') {
    const devInterfaceLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.devInterfaceLanguage) as LanguageCodesUIValues;

    if (devInterfaceLanguage && LANGUAGE_CODES_UI.includes(devInterfaceLanguage)) {
      return devInterfaceLanguage;
    }
  }

  const language = languageBySiteId[siteId as SiteIdValues]?.(referrer) || LANGUAGE_CODE_DEFAULT;

  return language;
}

export default getLanguage;
