import { isNil, omitBy } from 'lodash-es';

import type { DeployEnvValues } from '../types/deployEnv';

import config from '../config';

import { LOCAL_STORAGE_KEYS } from '@boldpl/base/constants/localStorage';
import loggerService from '@boldpl/base/services/loggerService';
import isType from '@boldpl/base/utils/isType';

interface MainCustomAttrs {
  readonly deployEnv: DeployEnvValues;
  host: string;
  hostname: string;
  isAutomated: boolean;
  readonly name: string;
  port: string;
  readonly version: string;
}

const newrelic = window?.newrelic;
const customAttributePrefix = 'customAttr_';
const isDebugMode = localStorage?.getItem(LOCAL_STORAGE_KEYS.newRelicDebug) === 'true';
const mainCustomAttrs: MainCustomAttrs = {
  // returns true for Cypress and selenium webdriver, https://developer.mozilla.org/en-US/docs/Web/API/Navigator/webdriver
  isAutomated: !!window.navigator.webdriver,

  get deployEnv() {
    return config.deployEnv;
  },
  host: window.location.host,
  hostname: window.location.hostname,
  get name() {
    return config.name;
  },
  port: window.location.port,
  get version() {
    return config.version;
  },
};

const debug = (...args: unknown[]) => loggerService.success('NEW RELIC', ...args);

export function prepareKey<T>(key: T): string | T {
  return typeof key === 'string' && !key.includes(customAttributePrefix) ? `${customAttributePrefix}${key}` : key;
}

type ValueToPrepare = Nullable<boolean | string | number | SomeObj>;

export function prepareValue(value?: ValueToPrepare): Nullable<string> {
  // Boolean, Number, null and undefined are changed to upper case strings
  if (value == null || isType(value, Boolean, Number)) {
    return String(value).toUpperCase();
  }

  if (isType(value, Object)) {
    return JSON.stringify(value);
  }

  if (value === '') {
    return 'EMPTY_STRING';
  }

  if (typeof value === 'string') {
    return value;
  }

  // return null for cleanedObject
  return null;
}

type CustomAttributes = Partial<MainCustomAttrs> | ValueToPrepare | symbol | SomeObj;

export function prepareCustomAttributes(customAttributes?: CustomAttributes) {
  try {
    const prefixedKeyPairs = Object.entries(customAttributes as Partial<MainCustomAttrs>).map(([key, value]) => [
      prepareKey(key),
      prepareValue(value),
    ]);
    const prefixedObject = Object.fromEntries(prefixedKeyPairs) as Record<string, Nullable<string>>;
    const cleanedObject = omitBy(prefixedObject, isNil) as Dictionary;

    return cleanedObject;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    return {};
  }
}

/**
 * Adds a user-defined attribute name and value to subsequent events on the page.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/set-custom-attribute
 * @param {string} name - name of custom attribute
 * @param {number|string} value - value of custom attribute
 */
const setCustomAttribute = (name: string, value: string | number) => {
  if (isDebugMode) {
    debug('setCustomAttribute', name, value);
  }

  newrelic?.setCustomAttribute?.(name, value);
};

/**
 * Method for dispatching errors to New Relic
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/notice-error
 * @param {error} error - error object - Required. Provide a meaningful error message that you can use when analyzing data
 * @param {object} [customAttributesRaw] - Optional. An object containing name/value pairs representing custom attributes.
 */
const submitError = (error: SomeError, customAttributesRaw?: SomeObj) => {
  // if error has originated from network request, then pass req as custom attribute (and res if available)
  if (error.request) {
    customAttributesRaw = {
      ...customAttributesRaw,
      req: error.request,
      ...(error.response && { res: error.response }),
    };
  }

  const customAttributes = prepareCustomAttributes(customAttributesRaw);

  if (isDebugMode) {
    debug('noticeError', error.message, error, customAttributes);
  }

  newrelic?.noticeError?.(error, customAttributes);
};

// todo ? move app init to html
const appInit = (name: string, id: string) => {
  if (isDebugMode) {
    debug('addRelease', name, id);
  }

  // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-release
  newrelic?.addRelease?.(name, id);

  Object.entries(prepareCustomAttributes(mainCustomAttrs)).forEach(([key, value]) => {
    setCustomAttribute(key, value);
  });
};

interface UserAttributes extends SomeObjExperimental {
  userId: string;
}

const userInfo = (userAttributes: UserAttributes) => {
  Object.entries(prepareCustomAttributes(userAttributes)).forEach(([key, value]) => {
    setCustomAttribute(key, value);
  });
};

/**
 * New Relic proxy service
 */
const newRelicService = {
  appInit,
  submitError,
  userInfo,
};

export type NewRelicService = typeof newRelicService;

export default newRelicService;
