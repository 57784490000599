import type { SvgPropsImg } from '@boldpl/base/types/svg';
import type { RequiredDeep } from 'type-fest';
import type { LanguageCodesUIValues } from '../constants/languageCode';
import type { AppInitParamsObj, CallbacksDefault } from '../types/appInit';
import type { CountryCodeValues } from '../types/countryCodes';
import type { DeployEnvValues } from '../types/deployEnv';
import type { UrlExternal } from '../types/urlExternal';
import type { UrlInternalMinimal } from '../types/urlInternal';
import type { SiteIdDataLoaded } from './types';

import { LOCAL_STORAGE_KEYS } from '@boldpl/base/constants/localStorage';

import getLanguage, { getBrowserLanguage } from '../i18n/getLanguage';

import { LANGUAGE_CODES_UI } from '../constants/languageCode';
import cssVariables from './cssVariables';
import { getApiHostname } from './getApiHostname';
import { getBugReport } from './getBugReport';
import { getDeployEnvFromHostname } from './getDeployEnvFromHostname';
import { initParamsPassed } from './initParamsPassed';
import { siteIdDataLoaded } from './siteIdDataLoaded';
import { version } from './version';

// import getReferrer from './getReferrer';

// vitest, .etc
window.mf ||= {};

const deployEnv = getDeployEnvFromHostname();
const enableDevMenu =
  (process.env.NODE_ENV !== 'test' &&
    (deployEnv === 'testing' ||
      deployEnv === 'local' ||
      localStorage.getItem(LOCAL_STORAGE_KEYS.devMenu) === 'true')) ||
  false;
const graphqlOverride = localStorage.getItem(LOCAL_STORAGE_KEYS.devGraphql);
const graphqlFTOverride = localStorage.getItem(LOCAL_STORAGE_KEYS.devFTGraphql);
const proxies = new WeakMap();

window.mf.TODO_TEMP_ENABLE_DEV_MENU = enableDevMenu;

// getters as this.language changes and also configSiteIdData is loaded in entry
const config = {
  get processDocumentCallback() {
    return initParamsPassed.processDocumentCallback;
  },
  get renderDocumentCallback() {
    return initParamsPassed.renderDocumentCallback;
  },

  // TODO XXX do sth with that
  get aiInterview() {
    return siteIdDataLoaded.tokens.aiInterview;
  },
  apiHostname: getApiHostname(),
  get assetsHostnameRewrite() {
    return initParamsPassed.assetsHostnameRewrite || {};
  },
  browserLanguage: getBrowserLanguage(),
  get callbacks() {
    return initParamsPassed.callbacks || ({} as CallbacksDefault);
  },
  get commonTokens() {
    return siteIdDataLoaded.tokens.common;
  },
  countryCD: 'US' as CountryCodeValues,
  css: Object.freeze({ ...cssVariables }), // TODO ? use address.country_cd for that
  deployEnv,
  enableDevMenu,
  get expertApply() {
    return siteIdDataLoaded.tokens.expertApply;
  },
  get feedback() {
    return siteIdDataLoaded.tokens.feedback;
  },
  get feedbackGraphql() {
    return ((deployEnv === 'local' && graphqlFTOverride) || initParamsPassed.feedbackGraphql) ?? '';
  },
  get footer() {
    return {
      copyrightText: initParamsPassed.footer?.copyrightText,
      displayFooter: initParamsPassed.footer?.displayFooter ?? true,
      ownerName: this.siteId === 'resumelab' ? 'Labs Limited' : 'Works Limited',
    };
  },
  // support for backend unavailable or errors during builder init - timeout: 30 sec
  globalError: { maxRetries: 1, multiplyTimeoutAmount: 1.25, timeout: 30_000 },
  get graphql() {
    return ((deployEnv === 'local' && graphqlOverride) || initParamsPassed.graphql) ?? '';
  },
  get isInAppWrapper() {
    return initParamsPassed.isInAppWrapper || false;
  },
  get isInFeatures() {
    return initParamsPassed.isInFeatures || false;
  },
  language: getLanguage(initParamsPassed.siteId),
  get languagesInterfaceAvailable() {
    return siteIdDataLoaded.interfaceLanguagesAvailable;
  },
  logo: {
    get mobile(): SvgPropsImg {
      return initParamsPassed.logo?.img || siteIdDataLoaded.logo.mobile;
    },
    get normal(): SvgPropsImg {
      return initParamsPassed.logo?.img || siteIdDataLoaded.logo.normal;
    },
    get simple(): SvgPropsImg {
      return initParamsPassed.logo?.img || siteIdDataLoaded.logo.simple;
    },
  },
  get logoPassedSize() {
    return initParamsPassed.logo?.size;
  },
  get name() {
    return initParamsPassed.name ?? siteIdDataLoaded.name;
  },
  get portalCD() {
    return siteIdDataLoaded.portalCD;
  },
  get portalID() {
    return siteIdDataLoaded.portalID;
  },
  get productCD() {
    return siteIdDataLoaded.productCD;
  },
  get productID() {
    return siteIdDataLoaded.productID;
  },
  get rendererProps() {
    return initParamsPassed.rendererProps;
  },
  get rest() {
    return initParamsPassed.rest || '';
  },
  get restAuth() {
    return initParamsPassed.restAuth || '';
  },
  get restEAFrontend() {
    return initParamsPassed.restEAFrontend || '';
  },
  get restEAJobs() {
    return initParamsPassed.restEAJobs || '';
  },
  get restEcom() {
    return initParamsPassed.restEcom || '';
  },
  get routingPathnamePrefix() {
    return initParamsPassed.routingPathnamePrefix || '';
  },
  get shouldDisableEditCvButton() {
    return initParamsPassed.shouldDisableEditCvButton || false;
  },
  get shouldDisplaySelectorDocumentChange() {
    return initParamsPassed.shouldDisplaySelectorDocumentChange || false;
  },
  get siteId() {
    return initParamsPassed.siteId;
  },
  get staticRoot() {
    return initParamsPassed.staticRoot || window.location.origin;
  },
  get urlEAExternal() {
    // Add language if it will be needed
    return initParamsPassed.urlEAExternal || {};
  },
  get urlEAInternal() {
    // Add language if it will be needed
    return initParamsPassed.urlEAInternal || {};
  },
  get urlExternal() {
    if (initParamsPassed.urlExternal) {
      if ('default' in initParamsPassed.urlExternal) {
        return initParamsPassed.urlExternal[this.language] || initParamsPassed.urlExternal.default;
      }

      return initParamsPassed.urlExternal;
    }

    return siteIdDataLoaded.urlExternal[this.language] || siteIdDataLoaded.urlExternal.default;
  },
  get urlInternal() {
    if (initParamsPassed.urlInternal) {
      if ('default' in initParamsPassed.urlInternal) {
        return initParamsPassed.urlInternal[this.language] || initParamsPassed.urlInternal.default;
      }

      return initParamsPassed.urlInternal;
    }

    return {}; // TODO change to undefined
  },
  version: version(),
  get visitor() {
    return { trackValue: siteIdDataLoaded.analytics.visitorsTrackValue };
  },
} satisfies Config<AppInitParamsObj>;

export type Config<
  /** IPP is initParamsPassed */
  IPP extends AppInitParamsObj<C, UI, RP>,
  /** SIDL is siteIdDataLoaded */
  C = CallbacksDefault,
  UI = UrlInternalMinimal,
  RP = SomeObjExperimental,
  SIDL extends SiteIdDataLoaded = SiteIdDataLoaded, // needed?
> = Readonly<{
  aiInterview: SIDL['tokens']['aiInterview']; // TODO to other
  apiHostname: string;
  assetsHostnameRewrite: NonUndefined<IPP['assetsHostnameRewrite']>;
  browserLanguage: LanguageCodesUIValues;
  callbacks: C; // TODO IPP['callbacks']?
  commonTokens: SIDL['tokens']['common'];
  countryCD: CountryCodeValues;
  css: Readonly<typeof cssVariables>;
  deployEnv: DeployEnvValues;
  enableDevMenu: boolean;
  expertApply: SIDL['tokens']['expertApply']; // TODO to other
  feedback: SIDL['tokens']['feedback']; // TODO to other
  feedbackGraphql: NonUndefined<IPP['feedbackGraphql']>; // TODO optional?
  footer: Readonly<{
    // RequiredDeep for TS hacking
    copyrightText?: RequiredDeep<IPP>['footer']['copyrightText'];
    displayFooter: RequiredDeep<IPP>['footer']['displayFooter'];
    ownerName: string;
  }>;
  globalError: Readonly<{
    maxRetries: number;
    multiplyTimeoutAmount: number;
    timeout: number;
  }>;
  graphql: NonUndefined<IPP['graphql']>; // TODO optional?
  isInAppWrapper: IPP['isInAppWrapper']; // TODO EA
  isInFeatures: IPP['isInFeatures'];
  languagesInterfaceAvailable: SIDL['interfaceLanguagesAvailable'];
  logo: Readonly<{
    // RequiredDeep for TS hacking
    mobile: RequiredDeep<IPP>['logo']['img'] | SIDL['logo']['mobile'];
    normal: RequiredDeep<IPP>['logo']['img'] | SIDL['logo']['normal'];
    simple: RequiredDeep<IPP>['logo']['img'] | SIDL['logo']['simple'];
  }>;
  // RequiredDeep for TS hacking
  logoPassedSize?: RequiredDeep<IPP>['logo']['size'];
  name: NonUndefined<IPP['name'] | SIDL['name']>;
  portalCD: SIDL['portalCD'];
  portalID: SIDL['portalID'];
  productCD: SIDL['productCD'];
  productID: SIDL['productID'];
  rendererProps?: RP; // TODO FT, IPP['rendererProps']?
  rest: NonUndefined<IPP['rest']>;
  restAuth: NonUndefined<IPP['restAuth']>;
  restEAFrontend: NonUndefined<IPP['restEAFrontend']>; // TODO EA
  restEAJobs: NonUndefined<IPP['restEAJobs']>; // TODO EA
  restEcom: NonUndefined<IPP['restEcom']>;
  routingPathnamePrefix: IPP['routingPathnamePrefix'];
  shouldDisableEditCvButton: IPP['shouldDisableEditCvButton']; // TODO FT
  shouldDisplaySelectorDocumentChange: IPP['shouldDisplaySelectorDocumentChange']; // TODO FT
  siteId: IPP['siteId'];
  staticRoot: IPP['staticRoot'];
  urlEAExternal?: IPP['urlEAExternal'];
  urlEAInternal?: IPP['urlEAInternal'];
  urlExternal: UrlExternal;
  urlInternal: UI; // TODO optional, IPP['urlInternal']?
  version: string;
  visitor: Readonly<{ trackValue: SIDL['analytics']['visitorsTrackValue'] }>;

  // eslint-disable-next-line typescript-sort-keys/interface
  processDocumentCallback?: IPP['processDocumentCallback'];
  renderDocumentCallback?: IPP['renderDocumentCallback'];
}> & {
  // writable
  language: LanguageCodesUIValues;
};

const proxifyTarget = (target: SomeObj, handler: SomeObj, proxyCache = proxies) => {
  if (proxyCache.has(target)) {
    return proxyCache.get(target);
  }

  const proxifiedTarget = new Proxy(target, handler);

  proxyCache.set(target, proxifiedTarget);

  return proxifiedTarget;
};

// target[key] !== null because typeof null === 'object' ;)
const handler = {
  deleteProperty(target: SomeObj, key: string) {
    if (typeof target[key] === 'object' && target[key] !== null) {
      return proxifyTarget(target[key], handler);
    }
    throw new Error(`Nope. Can't delete ${JSON.stringify(key)} from config.`);
  },
  get(target: SomeObj, key: string) {
    if (typeof target[key] === 'object' && target[key] !== null) {
      return proxifyTarget(target[key], handler);
    }

    return Reflect.get(target, key); // same as return target[key];
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(target: SomeObj, key: string, value: any) {
    if (key === 'language' && LANGUAGE_CODES_UI.includes(value)) {
      return Reflect.set(target, key, value); // Reflect.set(target, key, value); === target[key] = value; return true;
    }
    if (typeof target[key] === 'object' && target[key] !== null) {
      return proxifyTarget(target[key], handler);
    }
    throw new Error(`Nope. Can't set ${JSON.stringify(value)} to ${JSON.stringify(key)}.`);
  },
};

const proxifiedTarget: Config<AppInitParamsObj> = proxifyTarget(config, handler);

window.mf.getBugReport = getBugReport(proxifiedTarget);

export default proxifiedTarget;
