import { createIntl, createIntlCache } from 'react-intl';

import { isEmpty, isPlainObject, merge } from 'lodash-es';

import type { MessageReactIntl, MessageReactIntlValues } from '@boldpl/base/types/message';
import type { IntlShape, MessageFormatElement } from 'react-intl';
import type { LanguageCodesValues } from '../constants/languageCode';

import loggerService from '@boldpl/base/services/loggerService';

import newRelicService from '../services/newRelicService';

import { LANGUAGE_CODES_UI } from '../constants/languageCode';

// type IntlVersion = 'exampleDocumentContent' | 'main' | 'preview';
type IntlVersion = 'main';

export type MessagesJson = Dictionary;

// shape of imported json file
export interface ImportMessagesJson {
  default: MessagesJson;
}

export type MessagesJsonMultple = Partial<Record<LanguageCodesValues, MessagesJson>>;

export type Messages = Record<string, MessageFormatElement[]>;

type Data = Record<IntlVersion, Record<string, IntlShape>>; // string is locale here

interface ExtendData {
  data: Nullable<MessagesJsonMultple>;
}

const intlCache = createIntlCache();

const data: Data = {
  // exampleDocumentContent: {},
  main: {},
  // preview: {},
};

const extendData: ExtendData = { data: null };

export function processData(data2: MessagesJsonMultple, properLocaleKeys: string[]) {
  const properLanguages = LANGUAGE_CODES_UI;
  const extendDataEntries = Object.entries(data2);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: Record<string, { errors: string[]; value: any }> = Object.fromEntries(
    extendDataEntries.map((item) => {
      return [item[0], { errors: [], value: item[1] }];
    }),
  );
  const extendDataProcessed: MessagesJsonMultple = {};

  extendDataEntries.forEach((item) => {
    const language = item[0] as LanguageCodesValues;
    const languageData = item[1];
    const properLanguage = properLanguages.includes(language);
    const isProperObject = isPlainObject(languageData);
    const isEmptyObject = isEmpty(languageData);

    if (!properLanguage) {
      errors[language].errors.push(`Language not supported. Supported languages: ${properLanguages.join(', ')}`);
    }

    if (!isProperObject) {
      errors[language].errors.push(`Value is not an object`);

      return;
    }

    if (isEmptyObject) {
      errors[language].errors.push('Object is empty');

      return;
    }

    if (!properLanguage) {
      return;
    }

    const entries = Object.entries(languageData);
    const properEntries: MessagesJson = {};
    const wrongEntries: SomeObjExperimental = {};

    entries.forEach((entry) => {
      if (properLocaleKeys.includes(entry[0]) && typeof entry[1] === 'string' && entry[1].length > 0) {
        properEntries[entry[0]] = entry[1];
      } else {
        wrongEntries[entry[0]] = entry[1];
        if (entry[1] === '') {
          errors[language].errors.push(`Wrong entry, got empty string for key '${entry[0]}'`);
        } else if (!properLocaleKeys.includes(entry[0])) {
          errors[language].errors.push(`Wrong entry, key '${entry[0]}' not supported`);
        } else {
          errors[language].errors.push(`Wrong entry, only strings are supported, got '${entry[1]}' for '${entry[0]}'`);
        }
      }
    });

    if (!isEmpty(properEntries)) {
      extendDataProcessed[language] = properEntries;
    } else {
      errors[language].errors.push('No proper entries');
    }
  });

  const errorsFiltered = Object.fromEntries(Object.entries(errors).filter((item) => item[1].errors.length > 0));

  return { errorsFiltered, extendDataProcessed };
}

export function setExtendData(data2: Nullable<MessagesJsonMultple>, properLocaleKeys: string[]) {
  if (!isPlainObject(data2) || isEmpty(data2) || !Array.isArray(properLocaleKeys) || isEmpty(properLocaleKeys)) {
    return;
  }

  const { errorsFiltered, extendDataProcessed } = processData(data2, properLocaleKeys);

  if (!isEmpty(errorsFiltered)) {
    // eslint-disable-next-line no-console
    console.error('i18n extend errors', errorsFiltered);
  }

  if (process.env.NODE_ENV !== 'test') {
    loggerService.log('i18n extend data', extendDataProcessed);
  }

  extendData.data = extendDataProcessed;
}

export function getExtendData(locale: LanguageCodesValues) {
  if (!extendData.data) {
    return null;
  }

  const extendDataProcessedForLocale = extendData.data[locale];

  if (!extendDataProcessedForLocale) {
    // eslint-disable-next-line no-console
    console.warn("i18n extended data doesn't contain data for the current locale (selected app language)");

    return null;
  } else {
    return extendDataProcessedForLocale;
  }
}

interface AugmententedIntl {
  locale: LanguageCodesValues;
  messages: MessagesJson;
  version: IntlVersion;
}

// react-intl's methods are very sensitive for a lack of id, so we are making sure id is provided
const augmentedIntl = ({ locale, messages, version }: AugmententedIntl): IntlShape => {
  const intl = createIntl(
    {
      locale,
      messages,
    },
    intlCache,
  );

  // dirty and simple proxy for vitest and storybook (+ logging)
  if (process.env.NODE_ENV === 'test' || process.env.MF_IS_STORYBOOK === 'true') {
    return new Proxy(intl, {
      get(target, key) {
        if (key === 'formatMessage') {
          return (message: MessageReactIntl, values: MessageReactIntlValues) => {
            if (process.env.MF_IS_STORYBOOK === 'false' && values?.length) {
              console.log('react-intl - formatMessage (rest present)', message.id, values); // eslint-disable-line no-console
            }

            return message.id;
          };
        }

        // add additional methods if needed (used in intl form intls or react-intl's Components)

        return Reflect.get(target, key);
      },
    });
  }

  return new Proxy(intl, {
    get(target, key) {
      if (key === 'formatMessage') {
        // eslint-disable-next-line default-param-last
        return ({ id, ...restMessage }: MessageReactIntl = {}, values: MessageReactIntlValues) => {
          if (!id) {
            id = 'ERR_INTL_NO_ID';

            const error = new Error(`intl formatMessage ${version} ${locale} ${id}`);

            newRelicService.submitError(error);

            if (process.env.NODE_ENV === 'development') {
              console.error(error); // eslint-disable-line no-console
            }
          }

          return target.formatMessage(
            {
              id,
              ...restMessage,
            },
            values,
          );
        };
      }

      // add additional methods if needed (used in intl form intls or react-intl's Components)

      return Reflect.get(target, key);
    },
  });
};

interface AddIntl {
  additionalMessageData?: Nullable<MessagesJsonMultple>;
  locale?: LanguageCodesValues;
  messages?: MessagesJson;
  messagesMultiple?: MessagesJsonMultple;
  version?: IntlVersion;
}

export interface GeIntl {
  locale: LanguageCodesValues;
  version?: IntlVersion;
}

interface AddLocalesParamsObj {
  additionalMessageData?: Nullable<MessagesJsonMultple>;
  properLocaleKeys: string[];
}

const intls = {
  add({ locale, messages, messagesMultiple, version = 'main' }: AddIntl) {
    if (messages && locale) {
      data[version][locale] = augmentedIntl({
        locale,
        messages: merge(messages, getExtendData(locale)),
        version,
      });
    } else if (messagesMultiple) {
      data[version] = Object.fromEntries(
        Object.entries(messagesMultiple).map(([key, value]) => [
          key,
          augmentedIntl({
            locale: key as LanguageCodesValues,
            messages: merge(value, getExtendData(key as LanguageCodesValues)),
            version,
          }),
        ]),
      );
    } else {
      throw new Error('Bad `intls.add` config!');
    }
  },
  addAdditionalMessageData({ additionalMessageData, properLocaleKeys }: AddLocalesParamsObj) {
    if (extendData.data) {
      throw new Error('Intl can be extended only once!');
    }

    if (properLocaleKeys && additionalMessageData) {
      setExtendData(additionalMessageData, properLocaleKeys);
    }
  },
  get({ locale, version = 'main' }: GeIntl) {
    return data[version][locale];
  },
};

// dirty intl for vitest and storybook
if (process.env.NODE_ENV === 'test' || process.env.MF_IS_STORYBOOK === 'true') {
  const messages = new Proxy(
    {},
    {
      get(target, key) {
        return key;
      },
      // https://github.com/formatjs/formatjs/commit/269adc4a81af5d0cfe84ab02bd5242b4335e4a00
      getOwnPropertyDescriptor(target, key) {
        return {
          configurable: true,
          enumerable: true,
          value: key,
        };
      },
    },
  );

  intls.add({
    locale: 'en-US',
    messages,
    version: 'main',
  });
}

export default intls;
