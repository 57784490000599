import variables from '../styles/utils/_variablesExportForJS.module.scss';

/** Distances (rem) */
const distanceBase = parseFloat(variables.distanceBase); // 0.4rem
const distance8 = parseFloat(variables.distance8);
const distance12 = parseFloat(variables.distance12);
const distance16 = parseFloat(variables.distance16);
const distanceDefault = parseFloat(variables.distanceDefault); // 2.4rem
const distance32 = parseFloat(variables.distance32);
const distance40 = parseFloat(variables.distance40);
const distance48 = parseFloat(variables.distance48);
const distance56 = parseFloat(variables.distance56);
const distance64 = parseFloat(variables.distance64);
const distance72 = parseFloat(variables.distance72);
const distance80 = parseFloat(variables.distance80);
/** Font-sizes (rem) */
const font10 = parseFloat(variables.font10);
const font12 = parseFloat(variables.font12);
const font14 = parseFloat(variables.font14);
const font16 = parseFloat(variables.font16);
const font18 = parseFloat(variables.font18);
const font24 = parseFloat(variables.font24);
const font32 = parseFloat(variables.font32);
const font40 = parseFloat(variables.font40);
const font64 = parseFloat(variables.font64);

/** Font-weights */
const fontWeightNormal = parseFloat(variables.fontWeightNormal);
const fontWeightBold = parseFloat(variables.fontWeightBold);
const fontWeightExtraBold = parseFloat(variables.fontWeightExtraBold);

/** Dimensions (rem) */
const documentPageSpacing = parseFloat(variables.documentPageSpacing);
const logoWidth = parseFloat(variables.logoWidth);
const mobileBottombarHeight = parseFloat(variables.mobileBottombarHeight);
const mobileTopbarHeight = parseFloat(variables.mobileTopbarHeight);

// only vars passed from styles
const cssVariables = {
  distance8,
  distance12,
  distance16,
  distance32,
  distance40,
  distance48,
  distance56,
  distance64,
  distance72,
  distance80,
  distanceBase,
  distanceDefault,
  documentPageSpacing,
  font10,
  font12,
  font14,
  font16,
  font18,
  font24,
  font32,
  font40,
  font64,
  fontWeightBold,
  fontWeightExtraBold,
  fontWeightNormal,
  logoWidth,
  mobileBottombarHeight,
  mobileTopbarHeight,
};

export default cssVariables;
