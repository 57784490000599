import type { Config } from '.';
import type { AppInitMinimalParamsObj } from '../types/appInit';

import { DEVICES } from '@boldpl/base/constants/devices';

const emptyValue = '-------';

export const fetchBuildInfo = async () => {
  const headers = { Accept: 'text/plain', 'Content-Type': 'text/plain' };

  try {
    const response = await fetch('/js/buildInfo.txt', { headers });

    if (response.status !== 200) {
      return '';
    }

    const data = await response.text();

    return data
      .split('\n')
      .filter(Boolean)
      .map((info) => info.replace(/^(.*?)(:|$)/, '**$1**$2'))
      .join('\n');
  } catch {
    return '';
  }
};

const copyTextToClipboard = (text: string) => {
  let copiedToClipboard = false;

  if (window.navigator.clipboard !== undefined) {
    window.navigator.clipboard.writeText(text);
    copiedToClipboard = true;
  } else {
    // fallback
    const textArea = window.document.createElement('textarea');

    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    window.document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      copiedToClipboard = window.document.execCommand('copy');
    } catch {
      /* empty */
    }

    window.document.body.removeChild(textArea);
  }

  if (copiedToClipboard) {
    // eslint-disable-next-line no-alert
    window.alert('Copied to clipboard');
  }
};

export const getBugReport = (config: Config<AppInitMinimalParamsObj>) => async () => {
  // const { data } = storeService.getState().user;
  const data = { email: '', user_uid: '' }; // TODO XXX, pass the data
  const now = new Date();
  const buildInfo = await fetchBuildInfo();

  const reportMessage = `
## General
**Description**:
**Steps to reproduce**:
**Actual behavior**:
**Expected behavior**:
**Reporter**: ${emptyValue}
**Frequency of appearance**: ALWAYS 1/X
**Url**: ${window.location.href}

## Date
**Reported date**: ${now.toString()}
**Reported date UTC**: ${now.toISOString()}

## User
**User id**: ${data ? data.user_uid : 'Guest user'}
**User email**: ${data ? data.email : emptyValue}
**User password**: ${emptyValue}

## Env data
**Site id**: ${config.siteId}
**Deploy env**: ${config.deployEnv}
${buildInfo}

## Browser
**Browser**: ${window.navigator?.userAgent}
**Display mode**: ${Object.values(DEVICES).join(' ')}
**Screen width**: ${emptyValue}
**Screen height**: ${emptyValue}

## Logs

## Har file

## Visual proof
`;

  copyTextToClipboard(reportMessage);
  // eslint-disable-next-line no-console
  console.log(reportMessage);
};
