export const LANGUAGE_CODES = {
  CS: 'cs-CZ',
  DA: 'da-DK',
  DE: 'de-DE',
  EN: 'en-US',
  ENGB: 'en-GB',
  ES: 'es-ES',
  ESMX: 'es-MX',
  FR: 'fr-FR',
  HR: 'hr-HR',
  HU: 'hu-HU',
  IT: 'it-IT',
  NL: 'nl-NL',
  NO: 'nb-NO',
  PL: 'pl-PL',
  PT: 'pt-PT',
  PTBR: 'pt-BR',
  RO: 'ro-RO',
  SE: 'sv-SE',
  TR: 'tr-TR',
  UK: 'uk-UK', // Ukrainian, TODO uk-UA
} as const;

export type LanguageCodesValues = ValueOf<typeof LANGUAGE_CODES>;

export const LANGUAGE_CODE_DEFAULT = 'en-US' satisfies LanguageCodesValues;

export const LANGUAGE_CODES_TO_SHORT = {
  'cs-CZ': 'cs',
  'da-DK': 'da',
  'de-DE': 'de',
  'en-GB': 'enGB',
  'en-US': 'en',
  'es-ES': 'es',
  'es-MX': 'esMX',
  'fr-FR': 'fr',
  'hr-HR': 'hr',
  'hu-HU': 'hu',
  'it-IT': 'it',
  'nb-NO': 'no',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'pt-BR': 'ptBR',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'sv-SE': 'se',
  'tr-TR': 'tr',
  'uk-UK': 'uk',
} as const satisfies Record<LanguageCodesValues, string>;

export type LanguageCodesToShortKeys = keyof typeof LANGUAGE_CODES_TO_SHORT; // same as LanguageCodesValues;
export type LanguageCodesToShortValues = ValueOf<typeof LANGUAGE_CODES_TO_SHORT>;

export const LANGUAGE_CODES_UI = [
  'da-DK',
  'de-DE',
  'en-US',
  'es-ES',
  'fr-FR',
  'it-IT',
  'pl-PL',
  'pt-BR',
  'sv-SE',
  'uk-UK',
  'en-GB',
  'es-MX',
  'nl-NL',
  'pt-PT',
] as const satisfies LanguageCodesValues[];

export type LanguageCodesUIValues = (typeof LANGUAGE_CODES_UI)[number];

export type LanguageCodesPreviewValues = Exclude<LanguageCodesValues, 'es-MX'>;
