import type { DeployEnvValues } from '../types/deployEnv';

import { isLocal } from '../utils/isLocal';

import { hostsTestingToSiteId } from '../constants/hosts';

export const getDeployEnvFromHostname = (hostname = window.location.hostname): DeployEnvValues => {
  let env: DeployEnvValues = 'production';

  if (isLocal(hostname)) {
    env = 'local';
  }

  if (hostname.includes('testing.') || Object.keys(hostsTestingToSiteId).find((item) => hostname.endsWith(item))) {
    // or hostTestingPrefix
    env = 'testing';
  }

  return env;
};
