import hideInitLoader from '@boldpl/common/entry/hideInitLoader';
import rewriteUrlOnInit from '@boldpl/common/entry/rewriteUrlOnInit';
import { loadPolyfillsFull } from '@boldpl/common/polyfill';

import type { SiteTokens } from '@boldpl/common/config/types';
import type { SiteIdValues } from '@boldpl/common/constants/siteId';
import type { ImportMessagesJson } from '@boldpl/common/i18n/intls';

import { initParamsPassed } from '@boldpl/common/config/initParamsPassed';
import { siteIdDataLoaded } from '@boldpl/common/config/siteIdDataLoaded';
import { siteTokens as flexJobsTokens } from '@boldpl/common/config/tokens/fj';
import { siteTokens as mprTokens } from '@boldpl/common/config/tokens/mpr';
import { siteTokens as sonaraTokens } from '@boldpl/common/config/tokens/sonara';

import { getSiteIdFromUrl } from '@boldpl/common/utils/getSiteIdFromUrl';

import { siteIdValuesEAWrapper } from '@boldpl/common/constants/siteId';

import intls from '@boldpl/common/i18n/intls';

window.mf ||= {};

const tokensByStateIdMap: Partial<Record<SiteIdValues, SiteTokens>> = {
  flexjobs: flexJobsTokens,
  myperfectresume: mprTokens,
  sonara: sonaraTokens,
};

const nameBySiteIdMap: Partial<Record<SiteIdValues, string>> = {
  flexjobs: 'FlexJobs',
  myperfectresume: 'MyPerfectResume',
  sonara: 'Sonara',
};

const getTokensBySiteId = (siteId: SiteIdValues | undefined) => {
  if (!siteId) {
    return sonaraTokens;
  }

  return tokensByStateIdMap[siteId] || sonaraTokens;
};

const validateSiteId = (siteId?: SiteIdValues) => {
  if (!siteId) {
    throw new Error('siteId is not set');
  }

  if (!siteIdValuesEAWrapper.includes(siteId)) {
    throw new Error(
      `siteId ("${siteId}") not found in siteIdValuesEAWrapper. Possible values: ${siteIdValuesEAWrapper.join(', ')}`,
    );
  }
};

(async () => {
  await loadPolyfillsFull();

  const siteId = getSiteIdFromUrl();

  siteIdDataLoaded.name = (siteId && nameBySiteIdMap[siteId]) || (nameBySiteIdMap.sonara as string);

  validateSiteId(siteId);
  siteIdDataLoaded.tokens = getTokensBySiteId(siteId);
  initParamsPassed.siteId = siteId!; // ! after validateSiteId

  const renderNode = window.document.querySelector('#mf-expertapply-wrapper');

  if (!renderNode || !(renderNode instanceof HTMLElement)) {
    // TODO XXX ? throw some error
    return;
  }

  renderNode.dataset.mfEmbed = ''; // data-mf-embed selector for CSS variables

  rewriteUrlOnInit();

  const globMainLanguage = import.meta.glob('../i18n/locales/main/*.json');
  const defaultMainLanguage = (await globMainLanguage[`../i18n/locales/main/en-US.json`]()) as ImportMessagesJson;

  intls.add({ locale: 'en-US', messages: defaultMainLanguage.default, version: 'main' });

  const { mountApp } = await import('./mountApp');

  hideInitLoader('#mf-expertapply-wrapper-init-loader');

  mountApp(renderNode);
})();
