import { isLocal } from '../utils/isLocal';

import { hostTestingPrefix } from '../constants/hosts';
import { LOCAL_REGEX_PATTERN } from '../constants/localRegexPattern';

const isFeatures = (hostname = window.location.hostname) => !!hostname.match(hostTestingPrefix);

interface GetApiHostnameParamsObj {
  currentHostname?: string;
  hostnameRewrite?: Dictionary;
}

export const getApiHostname = ({
  currentHostname = window.location.hostname,
  hostnameRewrite,
}: GetApiHostnameParamsObj = {}) => {
  if (hostnameRewrite) {
    const hostname = hostnameRewrite[currentHostname];

    if (hostname) {
      return hostname;
    }
  }

  if (isLocal(currentHostname)) {
    return currentHostname.replace(LOCAL_REGEX_PATTERN, 'reg.');
  }

  if (isFeatures(currentHostname)) {
    return currentHostname.replace(hostTestingPrefix, 'reg.');
  }

  return currentHostname;
};
