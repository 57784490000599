// NEW_SITE_ID_HERE

const SITE_ID_NGB = {
  interviewme: 'interviewme',
  resumelab: 'resumelab',
  zetybr: 'zetybr',
  zetyde: 'zetyde',
  zetydk: 'zetydk',
  zetyes: 'zetyes',
  zetyfr: 'zetyfr',
  zetyit: 'zetyit',
  zetyse: 'zetyse',
} as const;

// EA = expertapply
export const SITE_ID_EA = {
  // TODO XXX for now, this is just for CSS skin and other tests
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',

  // TODO XXX for now, this is just for CSS skin and other tests
  // myperfectresume.com
  myperfectresume: 'myperfectresume',

  sonara: 'sonara',

  flexjobs: 'flexjobs',
} as const;

export const SITE_ID_EA_WRAPPER = {
  flexjobs: 'flexjobs',

  // TODO XXX for now, this is just for CSS skin and other tests
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',

  // TODO XXX for now, this is just for CSS skin and other tests
  // myperfectresume.com
  myperfectresume: 'myperfectresume',

  sonara: 'sonara',
} as const;

// FT = feedbacktool
export const SITE_ID_FT = {
  ...SITE_ID_NGB,

  // TODO - change to myperfectresumefr for clarity?
  moncvparfait: 'moncvparfait',
  // myperfectresume.com
  myperfectresume: 'myperfectresume',
  // meucurriculoperfeito.com.br
  myperfectresumebr: 'myperfectresumebr',
  // meinperfekterlebenslauf.de
  myperfectresumede: 'myperfectresumede',
  // micvideal.es
  myperfectresumees: 'myperfectresumees',
  // ilcvperfetto.it
  myperfectresumeit: 'myperfectresumeit',
  // micvideal.mx
  myperfectresumemx: 'myperfectresumemx',
  // myperfectcv.co.uk
  myperfectresumeuk: 'myperfectresumeuk',

  // livecareer.de
  livecareerde: 'livecareerde',
  // livecareer.es
  livecareeres: 'livecareeres',
  // livecareer.fr
  livecareerfr: 'livecareerfr',
  // livecareer.it
  livecareerit: 'livecareerit',
  // livecareer.pl
  livecareerpl: 'livecareerpl',
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',

  // LC LITE >>

  // livecareer.com.br
  livecareerbr: 'livecareerbr',
  // livecareer.com/dk
  livecareerdk: 'livecareerdk',
  // livecareer.mx
  livecareermx: 'livecareermx',
  // livecareer.com/nl
  livecareernl: 'livecareernl',
  // livecareer.pt
  livecareerpt: 'livecareerpt',
  // livecareer.com/se
  livecareerse: 'livecareerse',

  // LC LITE <<

  // livecareer.com
  livecareer: 'livecareer',
  // resume-now.com
  resumenow: 'resumenow',
} as const;

// IC = interviewcheck-ai
export const SITE_ID_IC = {
  // TODO XXX zetybr only?
  // zetybr: 'zetybr',
  ...SITE_ID_NGB,

  // TODO XXX for now, this is just for CSS skin testing
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',
  // myperfectresume.com
  myperfectresume: 'myperfectresume',
} as const;

// SC = socialcards
export const SITE_ID_SC = {
  // TODO XXX for now, this is just for CSS skin testing
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',
  // myperfectresume.com
  myperfectresume: 'myperfectresume',
} as const;

export const SITE_ID = {
  ...SITE_ID_NGB,
  ...SITE_ID_EA,
  ...SITE_ID_IC,
  ...SITE_ID_FT,
  ...SITE_ID_SC,

  // TODO XXX WORK IN PROGRESS new site ids
  zetycom: 'zetycom',
} as const;

export const siteIdValuesNGB = Object.values(SITE_ID_NGB);
export const siteIdValuesEA = Object.values(SITE_ID_EA);
export const siteIdValuesEAWrapper = Object.values(SITE_ID_EA_WRAPPER); // TODO XXX other siteIds
export const siteIdValuesFT = Object.values(SITE_ID_FT);
// export const siteIdValuesFeatures = Object.values(SITE_ID);
export const siteIdValuesIC = Object.values(SITE_ID_IC);
export const siteIdValuesSC = Object.values(SITE_ID_SC);

// export type SiteIdKeys = keyof typeof SITE_ID;
export type SiteIdValues = ValueOf<typeof SITE_ID>;
export type SiteIdValuesNGB = ValueOf<typeof SITE_ID_NGB>;

export type SiteIdDataNGB<T = SomeObj> = Record<ValueOf<typeof SITE_ID_NGB>, T>;
export type SiteIdData<T = SomeObj> = Record<SiteIdValues, T>;
